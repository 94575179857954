<template>
  <div ref="outerContainer" class="outer-container" :style="outerContainerStyle">
    <resize-observer @notify="handleResize" />
    <div ref="innerContainer" class="inner-container">
      <div
        v-if="containerWidth && containerHeight"
        :style="{ width: `${containerWidth}px`, height: `${containerHeight}px` }"
      >
        <div
          class="scale-container"
          :style="scaleContainerStyle"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MPreviewer',
  props: {
    resolution: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    aspectRatio: null,
    containerWidth: null,
    containerHeight: null,
    outerContainerStyle: {},
    scaleContainerStyle: {}
  }),
  watch: {
    resolution: {
      handler() {
        this.handleResize()
      },
      deep: true
    }
  },
  mounted() {
    this.handleResize()
  },
  methods: {
    handleResize() {
      const { width, height } = this.resolution
      this.aspectRatio = width && height ? width / height : null
      const outerContainerWidth = this.$refs.outerContainer.offsetWidth
      const outerContainerHeight = outerContainerWidth / this.aspectRatio
      this.outerContainerStyle.height = `${outerContainerHeight}px`
      this.$nextTick(() => {
        const { offsetWidth, offsetHeight } = this.$refs.innerContainer
        if (this.aspectRatio) {
          this.containerWidth = offsetWidth
          this.containerHeight = offsetHeight
          this.scaleContainerStyle.width = `${this.resolution.width}px`
          this.scaleContainerStyle.height = `${this.resolution.height}px`
          const scaleFactor = this.containerWidth / this.resolution.width
          this.scaleContainerStyle.transform = `scale(${scaleFactor})`
          return
        }
        this.containerWidth = offsetWidth
        this.containerHeight = offsetHeight
        this.scaleContainerStyle = {
          width: '100%',
          height: '100%'
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  .outer-container
    width: 100%
    overflow: hidden
    margin: 0
    padding: 0
    position: relative
  .inner-container
    position: absolute
    top: 1vmin
    left: 1vmin
    right: 1vmin
    bottom: 1vmin
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: center
  .scale-container
    transform-origin: left top
    > *
      width: 100%
      height: 100%
</style>
