<template>
  <v-app>
    <m-previewer :aspect-ratio="aspectRatio">
      <iframe
        v-if="componentType === 'layout' && layoutItem"
        :src="`${baseUrl}/layout/${layoutItemId}`"
        style="width: 100%; height: 100%;"
      />
      <component
        :is="mComponent.component"
        v-else-if="options"
        v-bind="options"
        style="width: 100%; height: 100%;"
        @open-page="openPage($event)"
      />
    </m-previewer>
  </v-app>
</template>

<script>
import MPreviewer from '@backend/components/MPreviewer'
import { resolveDefaultOptions } from '@/utils/resolve_widgets'
import { getAsyncCurrentUser } from '@/plugins/firebase'
export default {
  name: 'Preview',
  components: {
    MPreviewer
  },
  data: () => ({
    options: null,
    baseUrl: process.env.NODE_ENV === 'development'
      ? 'http://frame.mammutos.local:8080'
      : 'https://frame.mammutos.com'
  }),
  computed: {
    componentType() {
      return this.$route.params.type
    },
    layoutItemId() {
      return this.$route.params.id
    },
    layoutItem() {
      switch (this.componentType) {
        case 'widget':
          return this.$store.getters.layoutWidgetById(this.layoutItemId)
        case 'page':
          return this.$store.getters.layoutPageById(this.layoutItemId)
        case 'plugin':
          return this.$store.getters.layoutPluginById(this.layoutItemId)
        case 'layout':
          return this.$store.getters.layoutById(this.layoutItemId)
        default:
          return null
      }
    },
    mComponent() {
      switch (this.componentType) {
        case 'widget':
          return this.$store.getters.MWidgetById(this.layoutItem.widgetId)
        case 'page':
          return this.$store.getters.MPageById(this.layoutItem.pageId)
        case 'plugin':
          return this.$store.getters.MPluginById(this.layoutItem.pluginId)
        default:
          return null
      }
    },
    componentTypeName() {
      const firstLetter = this.componentType.substr(0, 1)
      return firstLetter.toUpperCase() + this.componentType.substr(1)
    },
    aspectRatio() {
      if (!this.layoutItem || !this.layoutItem.resolution) {
        return null
      }
      const { width, height } = this.layoutItem.resolution
      return width / height
    }
  },
  watch: {
    layoutItem() {
      if (this.componentType === 'layout') {
        return
      }
      if (this.layoutItem.options) {
        this.options = {}
        this.options = JSON.parse(JSON.stringify(this.layoutItem.options))
      } else if (!this.options) {
        this.options = resolveDefaultOptions(this.componentType, this.mComponent.component)
      }
    }
  },
  async beforeMount() {
    const user = await getAsyncCurrentUser()
    if (user) {
      this.$store.dispatch('bindMComponents')
      this.$store.dispatch('bindUserDocuments')
    }
  },
  methods: {
    openPage(pageId) {
      const page = this.$store.getters.layoutPageById(pageId)
      if (page) {
        alert(`Page with id ${pageId} found, will be opened`)
      } else {
        alert('Page to be opened not found')
      }
    }
  }
}
</script>
